.steps-content {
	min-height: 200px;
	margin-top: 16px;
	padding-top: 10px;
  padding-left:35px;
	text-align: center;
	background-color: #fafafa;
	border: 1px dashed #e9e9e9;
	border-radius: 2px;
}

.steps-action {
	margin-top: 24px;
}
.search-button {
	margin-left: 0px;
}
.search-form{
  margin-left: 10px;
}
