.backgroundImage {
	background: url('./sky.jpg') center/cover;
	z-index: -1;
}

.login-card {
	opacity: 0.9;

	box-shadow: 0 0 10px;
	z-index: 1;
}

.input:focus {
	z-index: 1;
}
.login-footer {
	text-align: center;
	color: white;
	background-color: transparent;
}
.login-header {
	color: white;
}
.logo {
	width: 3rem;
}
.login-form-button {
	width: 100%;
}
.ant-form-item-explain {
	min-height: 0px;
}
